import type {MutableRef} from 'preact/hooks';
import {useCallback, useEffect, useMemo, useRef, useState} from 'preact/hooks';

import {useBugsnag} from '~/foundation/Bugsnag/hooks';
import {useMonorail} from '~/foundation/Monorail/hooks';
import {usePasswordManagerDetection} from '~/hooks/usePasswordManagerDetection';
import type {LoginWithShopSdkUserAction} from '~/types/analytics';
import type {IframeElement} from '~/types/iframe';
import {isoDocument} from '~/utils/document';
import {AbstractShopJSError} from '~/utils/errors';
import {isValidEmail} from '~/utils/validators';
import {unzoomIos} from '~/utils/zoom';

import {useDebouncedCallback} from './useDebounce';

interface UserInformation {
  email?: string;
  firstName?: string;
  lastName?: string;
}

export interface UseEmailListenerProps {
  autoOpen?: boolean;
  debounce?: boolean;
  email?: string;
  emailInputSelector?: string;
  hideChange?: boolean;
  iframeRef: MutableRef<IframeElement | null>;
  shouldListen?: boolean;
}

/**
 * A reusable email listener hook. This hook listens to either the input event of a provided email input id
 * or a provided email prop and updates the user information in the Authorize iframe when the email provided
 * is a valid email address.
 *
 * Should there be no provided email input id or email, the hook does not do anything.
 */
export function useEmailListener({
  autoOpen,
  debounce,
  email,
  emailInputSelector,
  hideChange,
  iframeRef,
  shouldListen = true,
}: UseEmailListenerProps) {
  const {leaveBreadcrumb, notify} = useBugsnag();
  const {trackUserAction} = useMonorail();
  const {isFilledWithPasswordManager} = usePasswordManagerDetection({
    emailInputSelector,
  });
  const abortController = useRef<AbortController | null>(null);
  const [emailInputRef, setEmailInputRef] = useState<HTMLInputElement | null>(
    null,
  );
  const submittedEmail = useRef<string>('');

  const trackedEvents = useMemo(
    () => new Set<LoginWithShopSdkUserAction>(),
    [],
  );

  const getSubmittedEmail = () => submittedEmail.current;

  const updateUserInformation = useCallback(
    async (
      {email: providedEmail, firstName = '', lastName = ''}: UserInformation,
      skipWhenModalIsVisible = true,
    ) => {
      if (
        !iframeRef.current ||
        (iframeRef.current.visible && skipWhenModalIsVisible)
      ) {
        return;
      }

      const email = isValidEmail(providedEmail) ? providedEmail : '';

      if (abortController.current && !abortController.current?.signal.aborted) {
        abortController.current.abort();
      }

      abortController.current = new AbortController();

      try {
        const {loaded, open, postMessage, sessionDetected, waitForMessage} =
          iframeRef.current;

        if (!loaded) {
          await waitForMessage('loaded', abortController.current.signal);
        }
        if (!loaded && sessionDetected && autoOpen) {
          return;
        }

        postMessage({
          firstName,
          lastName,
          type: 'namesubmitted',
        });

        submittedEmail.current = email;
        postMessage({
          email,
          hideChange: hideChange === undefined ? email.length > 0 : hideChange,
          type: 'emailsubmitted',
        });
        leaveBreadcrumb(
          'email submitted',
          {email: email ? 'redacted' : ''},
          'state',
        );

        const shopUserMatchedPromise = waitForMessage(
          'shop_user_matched',
          abortController.current.signal,
        );
        const captchaChallengePromise = new Promise((resolve, reject) => {
          const waitForCaptcha = async () => {
            try {
              const response = await waitForMessage(
                'error',
                abortController.current!.signal,
              );

              if (
                response.type === 'error' &&
                response.code === 'captcha_challenge'
              ) {
                resolve(undefined);
              } else {
                waitForCaptcha();
              }
            } catch (err) {
              reject(err);
            }
          };

          waitForCaptcha();
        });

        await Promise.race([shopUserMatchedPromise, captchaChallengePromise]);

        // Open the Authorize modal.
        open();

        // Blur the input?
        emailInputRef?.blur();
        unzoomIos();

        abortController.current.abort();
      } catch (error) {
        if (
          error instanceof AbstractShopJSError &&
          error.name === 'AbortSignalReceivedError'
        ) {
          return;
        }
        if (error instanceof Error) {
          notify(
            new Error(
              `Error updating user info: ${error.name} - ${error.message}`,
            ),
          );
        }
      }
    },
    [autoOpen, emailInputRef, hideChange, iframeRef, leaveBreadcrumb, notify],
  );

  const trackAndUpdateEmail = useCallback(
    (email = '', skipWhenModalIsVisible?: boolean) => {
      const isValid = isValidEmail(email);

      if (
        isFilledWithPasswordManager &&
        !trackedEvents.has('PASSWORD_MANAGER_AUTOFILL_DETECTED')
      ) {
        trackedEvents.add('PASSWORD_MANAGER_AUTOFILL_DETECTED');

        trackUserAction({
          userAction: 'PASSWORD_MANAGER_AUTOFILL_DETECTED',
        });
      }

      if (isValid && !trackedEvents.has('EMAIL_ENTERED')) {
        trackedEvents.add('EMAIL_ENTERED');

        trackUserAction({
          userAction: 'EMAIL_ENTERED',
        });
        leaveBreadcrumb('email entered', {}, 'state');
      }

      updateUserInformation({email}, skipWhenModalIsVisible);
    },
    [
      isFilledWithPasswordManager,
      leaveBreadcrumb,
      trackUserAction,
      trackedEvents,
      updateUserInformation,
    ],
  );

  useEffect(() => {
    if (emailInputSelector) {
      const emailInput = isoDocument.querySelector(
        emailInputSelector,
      ) as HTMLInputElement | null;

      setEmailInputRef(emailInput);
    }
  }, [emailInputSelector]);

  const debouncedUpdateUserInfo = useDebouncedCallback((email: string) => {
    trackAndUpdateEmail(email);
  }, 200);

  useEffect(() => {
    if (!emailInputRef) {
      return;
    }

    const handler = () => {
      if (emailInputRef) {
        // Debounce the email change when the user is typing.
        debouncedUpdateUserInfo(emailInputRef.value);
      }
    };

    // Immediately check the input value if it was provided before this listener is attached.
    if (emailInputRef.value) {
      trackAndUpdateEmail(emailInputRef.value);
    }

    if (!shouldListen) {
      emailInputRef?.removeEventListener('input', handler);
      return;
    }

    emailInputRef.addEventListener('input', handler);

    return () => {
      emailInputRef?.removeEventListener('input', handler);
    };
  }, [
    emailInputRef,
    debouncedUpdateUserInfo,
    shouldListen,
    trackAndUpdateEmail,
  ]);

  useEffect(() => {
    if (email !== undefined) {
      // If the email is coming from the attribute,
      // force a user info update even when the modal is visible.
      if (debounce) {
        debouncedUpdateUserInfo(email);
      } else {
        trackAndUpdateEmail(email, false);
      }
    }
  }, [email, debouncedUpdateUserInfo, debounce, trackAndUpdateEmail]);

  return {
    getSubmittedEmail,
    updateUserInformation,
  };
}
