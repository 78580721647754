import {autoUpdate, flip, offset, shift} from '@floating-ui/dom';
import {classNames} from '@shopify/css-utilities';
import type {ComponentChildren, RefObject} from 'preact';
import {createPortal} from 'preact/compat';
import {useCallback, useEffect, useMemo, useRef, useState} from 'preact/hooks';

import {ShopIcon} from '~/components/icons/ShopIcon';
import {useI18n} from '~/foundation/I18n/hooks';
import {useRootProvider} from '~/foundation/RootProvider/hooks';
import {useFloating} from '~/hooks/useFloating';
import {arrow} from '~/hooks/useFloating/arrow';
import {useScreenSize} from '~/hooks/useScreenSize';
import type {PortalProviderVariant} from '~/types/portalProvider';
import {isoDocument} from '~/utils/document';
import {isoWindow} from '~/utils/window';

import {FocusLock} from '../FocusLock/FocusLock';
import {CloseIcon} from '../icons/CloseIcon';
import {PortalProvider} from '../PortalProvider/PortalProvider';

interface ModalProps {
  anchorTo?: string | RefObject<HTMLElement>;
  children?: ComponentChildren;
  hideHeader?: boolean;
  disableMinWidth?: boolean;
  key?: string;
  modalTitle?: string;
  onDismiss: () => void;
  onModalInViewport?: () => void;
  popupDisabled?: boolean;
  variant: PortalProviderVariant;
  visible?: boolean;
}

export const Modal = ({
  anchorTo,
  children,
  hideHeader = false,
  disableMinWidth = false,
  key,
  modalTitle = 'Sign in with Shop',
  onDismiss,
  onModalInViewport,
  popupDisabled,
  variant,
  visible,
}: ModalProps) => {
  const {translate} = useI18n();
  const anchorObserverRef = useRef<IntersectionObserver | null>(null);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const arrowRef = useRef<HTMLDivElement | null>(null);
  const modalObserverRef = useRef<IntersectionObserver | null>(null);
  const modalRef = useRef<HTMLElement | null>(null);
  const [modalOpened, setModalOpened] = useState(false);
  const {instanceId} = useRootProvider();
  const [initialDocumentOverflowValue, setInitialDocumentOverflowValue] =
    useState<string | undefined>();

  // If changing placement or fallbackPlacements, floatingArrow may need to be updated.
  const {floatingStyles, middlewareData, refs, update} = useFloating({
    middleware: [
      flip({
        crossAxis: false,
        fallbackPlacements: ['bottom', 'top'],
      }),
      shift({
        padding: 30,
      }),
      offset(30),
      arrow({
        element: arrowRef,
        padding: 28,
      }),
    ],
    placement: 'right',
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    if (anchorTo) {
      let element: HTMLElement | null;
      if (typeof anchorTo === 'string') {
        // Attempt to locate the element within the DOM
        element = isoDocument.querySelector(anchorTo);
      } else {
        element = anchorTo.current;
      }

      setAnchorElement(element);
      refs.setReference(element);
      update();
    }
  }, [anchorTo, refs, update]);

  if (!modalObserverRef.current) {
    modalObserverRef.current = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        const bounds = entry.boundingClientRect;

        if (bounds.top < 0) {
          isoWindow.scrollTo({
            top: 0,
            left: 0,
          });
        }

        if (entry.isIntersecting) {
          onModalInViewport?.();
        }
      }
    });
  }

  if (!anchorObserverRef.current) {
    anchorObserverRef.current = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        const bounds = entry.boundingClientRect;

        if (bounds.top < 0) {
          isoWindow.scrollTo({
            top: 0,
            left: 0,
          });
        }

        if (!entry.isIntersecting && (entry.target as HTMLElement).offsetTop) {
          // Get the height of the modal and divide it in half. Use that (plus 30px padding outside) as the
          // scroll position to ensure the modal is centered with the anchor element.
          const anchorHeight = anchorElement?.offsetHeight || 0;
          const modalHeight = modalRef.current?.offsetHeight || 0;
          const modalOffset = modalHeight / 2;
          const padding = 30;
          const offset = anchorHeight + modalOffset + padding;

          isoWindow.scrollTo({
            // 60 is used as a buffer to keep the modal from sticking to the top of the screen.
            // We add that value to the height of the anchor element to ensure that the anchor is fully visible.
            top: (entry.target as HTMLElement).offsetTop - offset,
          });
        }
      }
    });
  }

  // Disconnect observers when we unmount.
  useEffect(() => {
    return () => {
      if (modalObserverRef.current) {
        modalObserverRef.current.disconnect();
      }

      if (anchorObserverRef.current) {
        anchorObserverRef.current.disconnect();
      }
    };
  }, []);

  const {isDesktop} = useScreenSize();

  const positioning: 'center' | 'dynamic' = useMemo(() => {
    if (anchorElement && !popupDisabled && isDesktop) {
      return 'dynamic';
    }

    return 'center';
  }, [anchorElement, isDesktop, popupDisabled]);

  useEffect(() => {
    const documentElement = isoDocument.documentElement;
    const initialOverflow = documentElement?.style.overflow;

    /**
     * Reset document overflow value if the modal is unmounted, just in case
     * the modal was removed without the onDismiss callback being called.
     * */
    return () => {
      if (initialOverflow && documentElement) {
        documentElement.style.overflow = initialOverflow;
      } else {
        documentElement.style.removeProperty('overflow');
      }
    };
  }, []);

  const handleDismiss = useCallback(() => {
    onDismiss();
    if (initialDocumentOverflowValue) {
      isoDocument.documentElement.style.overflow = initialDocumentOverflowValue;
    } else {
      isoDocument.documentElement.style.removeProperty('overflow');
    }
  }, [initialDocumentOverflowValue, onDismiss]);

  useEffect(() => {
    function downHandler({key}: KeyboardEvent) {
      if (key === 'Escape' || key === 'Esc') {
        handleDismiss();
      }
    }

    isoWindow.addEventListener('keydown', downHandler);

    return () => {
      isoWindow.removeEventListener('keydown', downHandler);
    };
  }, [handleDismiss]);

  useEffect(() => {
    if (visible) {
      // Lock the page behind the overlay to prevent scrolling so our
      // modal doesn't become detached from the anchor element.
      setInitialDocumentOverflowValue(
        isoDocument.documentElement.style.overflow,
      );
      isoDocument.documentElement.style.overflow = 'hidden';

      if (modalObserverRef.current && modalRef.current) {
        modalObserverRef.current.observe(modalRef.current);
      }

      if (anchorObserverRef.current && anchorElement) {
        anchorObserverRef.current.observe(anchorElement);
      }
    } else {
      if (modalObserverRef.current && modalRef.current) {
        modalObserverRef.current.unobserve(modalRef.current);
      }

      if (anchorObserverRef.current && anchorElement) {
        anchorObserverRef.current.unobserve(anchorElement);
      }
    }
  }, [anchorElement, visible]);

  useEffect(() => {
    if (!visible) {
      setModalOpened(false);
      return;
    }

    const handleTransitionEnd = () => {
      setModalOpened(true);
    };

    /**
     * Update the modalOpened state after the modal has finished transitioning.
     * This ensures that the modal completes the transition before attempting to set focus in the FocusLock component.
     * This ensures the transition plays smoothly.
     */
    modalRef.current?.addEventListener('transitionend', handleTransitionEnd, {
      once: true,
    });

    return () => {
      modalRef.current?.removeEventListener(
        'transitionend',
        handleTransitionEnd,
      );
    };
  }, [visible]);

  const backgroundClassName = classNames(
    'fixed bottom-0 left-0 right-0 top-0 z-10 bg-overlay duration-200 ease-cubic-modal motion-reduce_duration-0',
    visible ? 'opacity-100' : 'opacity-0',
  );

  const containerClassName = classNames(
    'fixed bottom-0 left-0 right-0 top-0 z-max overflow-hidden',
    positioning === 'center' && 'flex items-center justify-center',
    !visible && 'pointer-events-none',
  );

  const modalClassName = classNames(
    'relative z-50 bg-white duration-200 ease-cubic-modal will-change-transform focus_outline-0 motion-reduce_duration-0 sm_absolute sm_bottom-0 sm_left-0 sm_right-0 sm_top-auto sm_rounded-b-none',
    visible
      ? 'opacity-1 delay-200 sm_translate-y-0'
      : 'opacity-0 sm_translate-y-full',
    positioning === 'dynamic' && visible ? 'scale-100' : '',
    positioning === 'dynamic' && !visible ? 'scale-0 sm_scale-100' : '',
    !disableMinWidth && 'min-w-85',
    !hideHeader && 'rounded-lg',
  );

  const modalContentClassName = classNames(
    'relative overflow-hidden sm_rounded-b-none',
    !hideHeader && 'rounded-lg',
  );

  /**
   * Positions the arrow based on the modal position fallbacks.
   *
   * Options:
   * - 1: Modal not anchored. Early return.
   * - 2: Modal anchored right. Arrow uses the top offset to continue pointing to the anchor,
   * even if the input is not vertically centered in the viewport.
   * - 3: Modal anchored top. Arrow at the bottom.
   * - 4: Modal anchored bottom. Arrow at the top.
   */
  const floatingArrow = useMemo(() => {
    if (positioning === 'center') {
      return null;
    }

    // A bottom overflow means the modal is using the top position fallback.
    const modalOnTop = middlewareData.flip?.overflows?.some(
      (overflow) => overflow.placement === 'bottom',
    );

    // No overflows means the modal is using the default position on the right.
    const modalOnRight = middlewareData.flip?.overflows === undefined;

    let top;
    let bottom;

    if (modalOnRight) {
      top = middlewareData.arrow?.y;
    } else if (modalOnTop) {
      bottom = '-10px';
    } else {
      top = '-10px';
    }

    const arrowClassname = classNames(
      'absolute z-30 block size-6 rotate-45 rounded-xs delay-200 duration-200 ease-cubic-modal sm_hidden',
      modalOnTop ? 'bg-grayscale-l4' : 'bg-white',
    );

    return (
      <div
        className={arrowClassname}
        data-testid="authorize-modal-arrow"
        ref={arrowRef}
        style={{
          bottom,
          left: middlewareData.arrow?.x || '-10px',
          top,
        }}
      />
    );
  }, [
    middlewareData.arrow?.x,
    middlewareData.arrow?.y,
    middlewareData.flip?.overflows,
    positioning,
  ]);

  const modalStyleValue =
    positioning === 'dynamic' ? floatingStyles : undefined;
  const modalHeader = hideHeader ? null : (
    <div className="flex w-full items-center justify-between p-4 pb-2">
      <ShopIcon className="h-4-5 text-purple-primary" />
      <button
        aria-label={
          translate('button.close', {
            defaultValue: 'Close',
          }) as string
        }
        className="group relative z-50 flex h-6 w-6 cursor-pointer rounded-max"
        data-testid="authorize-modal-close-button"
        type="button"
        onClick={handleDismiss}
      >
        <CloseIcon className="h-6 w-6 text-grayscale-l4 transition-colors group-hover_text-grayscale-l2l" />
        <div className="absolute inset-05 -z-10 rounded-max bg-grayscale-primary-light" />
      </button>
    </div>
  );

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const ariaHiddenProps = visible ? {} : {'aria-hidden': true};

  return createPortal(
    <PortalProvider
      instanceId={instanceId}
      key={key}
      type="modal"
      variant={variant}
    >
      <div
        className={containerClassName}
        data-testid="authorize-modal-container"
      >
        <div
          {...ariaHiddenProps}
          className={backgroundClassName}
          onClick={handleDismiss}
        />

        <FocusLock
          as="section"
          disabled={!modalOpened}
          aria-modal="true"
          {...ariaHiddenProps}
          aria-label={modalTitle}
          className={modalClassName}
          data-testid="authorize-modal"
          data-visible={visible}
          part="modal"
          ref={(ref: HTMLElement | null) => {
            modalRef.current = ref;
            if (anchorElement) {
              refs.setFloating(ref);
              update();
            }
          }}
          role="dialog"
          style={modalStyleValue}
        >
          <div className={modalContentClassName}>
            {modalHeader}
            {children}
          </div>
          {floatingArrow}
        </FocusLock>
      </div>
    </PortalProvider>,
    isoDocument.body,
  );
};

/**
 * Functionality not yet implemented:
 *
 * - positionOverrides
 */
